























































































import { Component, Prop, Vue } from "vue-property-decorator"
import House, { PropertyType } from "~/models/House"
import Agent from "~/models/Agent"
import Verification from "~/models/Verification"
import OcularDialog from "~/components/OcularDialog.vue"
import AgentInteractionRating from "@/components/agents/AgentInteractionRating.vue"
import snackbar from "~/plugins/snackbar"
import { state } from "@/store"
import OcularRatingAdvise from "~/components/OcularRating/OcularRatingAdvise.vue"
import router from "@/router"

@Component({
  components: {
    OcularDialog,
    OcularRatingAdvise,
    AgentInteractionRating,
    AgentCard: () => import("@/components/agents/AgentCard.vue"),
  },
})
export default class ValidateVerification extends Vue {
  @Prop({ required: true }) house!: House
  currentAgent: Agent = new Agent()
  agentRating: number | null = null
  verificationFull: boolean | null = null
  mandatoryFields = [
    {
      name: "living_area",
      error_message: "La surface du bien est obligatoire",
    },
    {
      name: "room_count",
      error_message: "Le nombre de pièces du bien est obligatoire",
    },
    {
      name: "bedroom_count",
      error_message: "Le nombre de chambres est obligatoire",
    },
  ]

  get ocularDialog() {
    return this.$refs.ocularDialog as OcularDialog
  }
  get agents() {
    return this.house.agencies?.map((agency) => agency.agents).flat() || []
  }
  get addressIsValid() {
    return !!(this.house.address || this.house.address_text)
  }
  get floorIsValid() {
    return (
      this.house.property_type !== PropertyType.flat ||
      (this.house.floor !== null && !isNaN(this.house.floor))
    )
  }
  get invalidMandatoryFields() {
    return this.mandatoryFields.filter((field) => {
      // @ts-ignore
      const housePropValue = this.house[field.name]
      return (
        housePropValue === null ||
        housePropValue === "" ||
        isNaN(housePropValue)
      )
    })
  }
  get verificationIsValid() {
    return (
      this.addressIsValid &&
      this.floorIsValid &&
      this.invalidMandatoryFields.length === 0
    )
  }
  rate(value: number) {
    this.agentRating = value
  }
  open() {
    this.ocularDialog.open = true
  }
  selected(agent: Agent) {
    if (this.currentAgent == agent) return "selected"
  }
  async validateVerification() {
    let verification = new Verification()
    if (this.house.verification) verification.assign(this.house.verification)
    else {
      verification.house_id = this.house.id
      verification.deal_id = state.currentDealId()
    }
    verification.done = true
    verification.agency_id = this.currentAgent.agency_id
    verification.agent_id = this.currentAgent.id
    verification.rating = this.agentRating
    verification.full =
      this.verificationIsValid && this.verificationFull
        ? this.verificationFull
        : false
    await verification.saveAndAssign()
    await this.house.fetch()
    this.house.verification_status = "Verified"
    state.notifications.fetch()
    snackbar({
      color: "success",
      content: `C'est bon ça ! La vérification est validée.`,
    })
  }

  goToAgencyTab() {
    const newRoute = this.$route.fullPath.replace("verification", "agencies")
    if (newRoute != this.$route.fullPath) router.push(newRoute)
  }

  notEmptyFull = (value: number | null) => value !== null || "Champ requis"
}
